import React from "react";
// import Link from "@mui/material/Link";

import Comp1 from "../assets/json/Comp 1.json";
import Lottie from "lottie-react";
// import { Button } from "@mui/material";

export default function NoMatch() {
  return (
    <>
      <Lottie animationData={Comp1} />
      {/* <Button className='absolute top-[646px] left-[380px] bg-blue-700 p-[20px] text-white font-bold rounded-3xl w-[203px]' ><Link href="/arenaSelect"  className="text-white font-bold ">Go Arena</Link></Button> */}
    </>
  );
}
