import React from "react";
import Dashboard from "../components/Dashboard";
import PageNotFound from "../extra/NoMatch";
import { RegisterArena } from "../components/RegisterArena";
import { Routes, Route } from "react-router-dom";
import ArenaSelect from "../components/ArenaSelect";

export default function AppRouting() {
  return (
    <>
      <Routes>
        <Route path="/kwanDashboardTech/" element={<Dashboard />} />
        <Route path="/kwanDashboardTech/:id" element={<ArenaSelect />} />
        <Route path="/kwanDashboardTech/*" element={<PageNotFound />} />
        <Route
          path="/kwanDashboardTech/RegisterArena"
          element={<RegisterArena />}
        />
      </Routes>
    </>
  );
}
