import React from "react";
import Lottie from "lottie-react";
import LoaderBall from "../assets/json/ball.json";

function Loader() {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
        {/* Adjust the width based on your design preferences */}
        <Lottie animationData={LoaderBall} />
        <p className="text-white font-bold text-md text-center">Loading...</p>
      </div>
    </div>
  );
}

export default Loader;
