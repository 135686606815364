import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface authState {
  loggedInUser: any;
  loggedInDetails: any;
}

const initialState: authState = {
  loggedInUser: localStorage.getItem("accessToken"),
  loggedInDetails: localStorage.getItem("employeeID"),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<any>) => {
      localStorage.setItem("accessToken", action.payload);
      localStorage.setItem("isLogin", "true");
      state.loggedInUser = action.payload;
    },
    loginDetails: (state, action: PayloadAction<any>) => {
      // console.log("auth", action.payload);
      localStorage.setItem("employeeID", action.payload);
      state.loggedInDetails = action.payload;
    },
    logout: (state, action) => {
      state.loggedInUser = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("employeeID");
      localStorage.removeItem("arena");
      localStorage.removeItem("isLogin");
    },
  },
});

export const loggedIn = (state: RootState) => state.auth.loggedInUser;

// Action creators are generated for each case reducer function
export const { login, logout, loginDetails } = authSlice.actions;
// export const authActions = authSlice.actions

export default authSlice.reducer;
