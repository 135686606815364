import React, { useState, useEffect } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import FastfoodTwoToneIcon from "@mui/icons-material/FastfoodTwoTone";
import ReactEChart from "echarts-for-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosURLS from "../services/Api";
import uuid from "react-uuid";
import areanSummmary from "../services/arenaSummary.json";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SportsCricketIcon from "@mui/icons-material/SportsCricket";
import Loader from "./loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function ArenaSelect() {
  const [activeTab, setActiveTab] = useState("details");
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [modalText, setModalText] = useState("");
  // const [inputValue, setInputValue] = useState("");
  const [arenaSummary, setArenaSummary] = useState({
    name: " ",
    address: {
      line1: " ",
      line2: " ",
      city: " ",
      state: " ",
      zipcode: " ",
      country: " ",
      landmark: " ",
    },
    contactDetail: {
      mobileNumber: " ",
      email: " ",
    },
    arenaImageId: "",
  });
  const [fileData, setFileData] = useState("");
  // const [bayData, setBayData] = useState([]);
  const [extendedData, setExtendedData] = useState<any>({});
  const [fromDate, setFromDate] = useState(new Date("2024-04-12"));
  const [toDate, setToDate] = useState(new Date("2024-05-12"));
  // const [expanded, setExpanded] = useState<string | false>(false);
  // const [loader, setLoader] = React.useState(false);
  const arenaID = useSelector((state: any) => state.arenaId.arenaIdUser);

  const navigate = useNavigate();
  const maxDate = new Date();

  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };

  const handleBack = () => {
    navigate("/kwanDashboardTech/");
  };

  // const openModal = (text: any) => {
  //   setIsModalOpen(true);
  //   setModalText(text);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  // const handleInputChange = (e: any) => {
  //   setInputValue(e.target.value);
  // };

  useEffect(() => {
    setLoader(true);
    if (arenaID) {
      axiosURLS.get(`/arena/` + arenaID).then((response) => {
        setArenaSummary(response.data);
        if (response.data?.arenaImageId) {
          axiosURLS
            .get(`/fileStorage/${response.data?.arenaImageId}`)
            .then(({ data }) => {
              setFileData(data.fileData);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    }
  }, [navigate, arenaID]);

  useEffect(() => {
    if (arenaID) {
      axiosURLS
        .post("arenaDetails/extended", {
          fromDate: fromDate.toISOString().split("T")[0],
          toDate: toDate.toISOString().split("T")[0],
          arenaId: arenaID,
        })
        .then((response) => {
          setExtendedData(response.data);
          setLoader(false);
        })
        .catch((error) => {
          navigate("/kwanDashboardTech/*");
        });
    }
  }, [fromDate, toDate, arenaID, navigate]);

  const handleFromDateChange = (date: Date) => {
    if (date > toDate) {
      setToDate(date);
    }
    setFromDate(date);
  };

  const handleToDateChange = (date: Date) => {
    if (date < fromDate) {
      setFromDate(date);
    }
    setToDate(date);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row items-center p-4">
        <button
          onClick={handleBack}
          className="w-16 block bg-black mt-12  text-white font-thin p-1 rounded-md shadow-md hover:bg-gray-800 fixed left-20 top-16 md:left-28 md:top-[5em]"
        >
          <span className="mr-1 font-bold w-5 text-center">&larr;</span>
        </button>
        <div className="flex flex-col md:flex-row items-center mt-20 md:mt-8 mb-4 px-60">
          <div className="flex flex-col md:flex-row md:items-center mb-4 md:mb-0">
            <label className="mr-2 text-white">From Date:</label>
            <DatePicker
              selected={fromDate}
              onChange={handleFromDateChange}
              dateFormat="yyyy-MM-dd"
              className="mr-2 p-2 border rounded"
              maxDate={maxDate}
            />
          </div>
          <div className="flex flex-col md:flex-row md:items-center">
            <label className="mr-2 text-white">To Date:</label>
            <DatePicker
              selected={toDate}
              onChange={handleToDateChange}
              dateFormat="yyyy-MM-dd"
              className="p-2 border rounded"
              maxDate={maxDate}
            />
          </div>
        </div>
      </div>
      {loader && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader />
        </div>
      )}
      {!loader && (
        // <main className="scrollbar-hide overflow-y-scroll max-h-[30em] mb-12 sm:mx-6 lg:mx-12">
        <main className="overflow-y-auto scrollbar-hide  max-h-[32em] md:max-h-[35em] mx-20 py-12">
          <div className=" lg:grid lg:grid-cols-4 bg-gradient-to-r bg-[#595c749a]  ">
            <div>
              {fileData ? (
                <img
                  src={`data:image/png;base64,${fileData}`}
                  alt="ArenaImage"
                  className="bg-[#cfe0fa] w-[720px] lg:flex lg:w-[600px] lg:h-[250px] object-cover self-center"
                />
              ) : (
                <img
                  src={`https://imgs.search.brave.com/BaN8UNZLNJ73wXbbtF1zujdueTBqllqIGH67Hv2OapE/rs:fit:500:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAyLzc2LzI5LzYx/LzM2MF9GXzI3NjI5/NjE5M19nVjBwd0Ns/Y05meXN2ZnVROFgy/R2cxUGo1RXVkZkxU/Qy5qcGc`}
                  alt="StaticImage"
                  className="bg-[#cfe0fa] w-[720px] lg:flex lg:w-[600px] lg:h-[250px] object-cover self-center"
                />
              )}
            </div>

            <div
              className="buttons"
              // onClick={() => openModal("Arena Support Request 1")}
            >
              <div>
                <SportsCricketIcon fontSize="large" />
              </div>
              <button className="text-white font-bold rounded-lg text-lg px-5 py-2.5 text-center mr-2 mb-2">
                Arena Support Request
              </button>
            </div>
            <div
              className="buttons"
              // onClick={() => openModal("F&B Support Request 1")}
            >
              {" "}
              <div>
                <FastfoodIcon />
              </div>
              <button className=" text-white font-bold rounded-lg text-lg px-5 py-2.5 text-center mr-2 mb-2 ">
                F&B Support Request
              </button>
            </div>
            <div
              className="buttons"
              // onClick={() => openModal("Other Support Request 1")}
            >
              {" "}
              <div>
                <SupportAgentIcon />
              </div>
              <button className="  text-white font-bold rounded-lg text-lg px-5 py-2.5 text-center mr-2 mb-2">
                Other Support Request
              </button>
            </div>
          </div>
          <div className="lg:grid lg:grid-cols-4 ">
            <div>
              <div className="bg-[#595c749a]">
                <div className="p-5 flex lg:flex-col text-center lg:items-center lg:justify-center">
                  <h6 className="sm:text-[1rem] sm:font-[700] text-[#82f3ffcf] ">
                    {arenaSummary.name} Virtue Analytics Private Ltd
                  </h6>

                  <h6 className=" indent-[-3.75rem] text-white  font-[900] text-base tracking-wide space-x-1 pl-10 ">
                    <LocationOnIcon className="text-red-600 text-[3rem] pl-5" />

                    <>
                      <span> {arenaSummary.address.line1} </span>
                      <span> {arenaSummary.address.line2} </span>
                      <span> {arenaSummary.address.landmark} </span>
                      <span> {arenaSummary.address.city} </span>
                      <span> {arenaSummary.address.state} </span>
                      <span> {arenaSummary.address.zipcode} </span>
                      <span> {arenaSummary.address.country} </span>
                    </>
                  </h6>
                </div>
              </div>
              <div
                key={uuid()}
                className="bg-[#4A148C] flex flex-col justify-around py-8 text-center "
              >
                <div className="pb-6">
                  <h2 className="card divide-x">
                    {extendedData?.totalBookings}
                  </h2>
                  <h1 className="card divide-x text-white">
                    {" "}
                    <LibraryBooksIcon /> Total Booking{" "}
                  </h1>
                </div>
                <div className="py-6 border-t border-b">
                  <h2 className="card divide-x">
                    ₹ {extendedData?.arenaRevenue?.bookingRevenue}
                  </h2>
                  <h1 className="card divide-x text-white">Booking Revenue</h1>
                </div>
                <div className="pt-6">
                  <h2 className="card divide-x">
                    ₹ {extendedData?.arenaRevenue?.fnbRevenue}
                  </h2>
                  <h1 className="card divide-x text-white">
                    {" "}
                    <FastfoodTwoToneIcon /> F & B Sales{" "}
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-span-3 p-6 bg-[#cfe0fa] gap-1 ">
              <div className="flex  flex-col md:flex-row gap-2">
                <button
                  className={`p-3 rounded ${
                    activeTab === "tableWiseDetails"
                      ? "bg-black text-white"
                      : "bg-[#e8e7e7bb] text-black"
                  }`}
                  onClick={() => handleTabChange("tableWiseDetails")}
                >
                  TableWise Details
                </button>
                <button
                  className={`p-3 rounded ${
                    activeTab === "details"
                      ? "bg-black text-white"
                      : "bg-[#e8e7e7bb] text-black"
                  }`}
                  onClick={() => handleTabChange("details")}
                >
                  BayWise Details
                </button>
                <button
                  className={`p-3 rounded ${
                    activeTab === "graph"
                      ? "bg-black text-white"
                      : "bg-[#e8e7e7bb] text-black"
                  }`}
                  onClick={() => handleTabChange("graph")}
                >
                  Graph
                </button>
              </div>

              {/* Render content based on the active tab */}
              {activeTab === "graph" && (
                <div className="flex flex-wrapcharts">
                  {areanSummmary[0].charts.map((chart: any) => {
                    return (
                      <div key={uuid()} className="w-1/2 p-2">
                        <ReactEChart key={uuid()} option={chart} />
                      </div>
                    );
                  })}
                </div>
              )}
              {activeTab === "details" && (
                <div className="relative overflow-auto scrollbar-hide m-4 h-[20rem]">
                  {/* {loader && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Loader />
                    </div>
                  )} */}

                  {!loader && (
                    <div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-14">
                        {extendedData?.bayWiseDetails?.map((bay: any) => (
                          <div
                            key={bay.bayNumber}
                            className="flex flex-col justify-between p-4 bg-gradient-to-b from-pink-100 to-pink-200 shadow-lg rounded-lg h-full"
                          >
                            <div>
                              <p className="font-bold text-lg text-pink-700">
                                Bay Number:{" "}
                                {bay.bayNumber ? bay.bayNumber : "000"}
                              </p>
                              <p>Occpancy: {bay.totalOccupancyInHours}</p>
                              <p> total booking : {bay.totalBookings}</p>
                              <p>
                                BookingRevenue:{" "}
                                {bay.totalRevenue.bookingRevenue}
                              </p>
                              <p>FnbRevenue: {bay.totalRevenue.fnbRevenue}</p>

                              {/* {/* Add more bay information as needed */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {activeTab === "tableWiseDetails" && (
                <div className="relative overflow-auto scrollbar-hide m-4 h-[20rem]">
                  {loader && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      Loading
                    </div>
                  )}

                  {!loader && (
                    <div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        {extendedData?.tableWiseDetails?.map((bay: any) => (
                          <div
                            key={uuid()}
                            className="flex flex-col justify-between p-4 bg-gradient-to-b from-pink-100 to-pink-200 shadow-lg rounded-lg h-full"
                          >
                            <div>
                              <p className="font-bold text-lg text-pink-700">
                                Table Number :{" "}
                                {bay.tableNumber ? bay.tableNumber : "000"}
                              </p>
                              <p>Total Bookings: {bay.totalBookings}</p>
                              <p>Total Revenue: {bay.totalRevenue}</p>

                              {/* {/* Add more bay information as needed */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <div className="flex flex-col justify-between">
                  <p>{modalText}</p>
                  <input
                    className="border border-gray-300 p-2 rounded-md w-full my-[40px]"
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Enter something..."
                  />
                  <button
                    className="bg-blue-500 w-[9.25rem] mx-[15rem]  text-white font-bold rounded-lg text-lg px-5 py-2.5 text-center"
                    onClick={handleModalButtonClick}
                  >
                    Click me!
                  </button>
                </div>
              </div>
            </div>
          )} */}
        </main>
      )}
    </>
  );
}
