import React, { useState, useEffect } from "react";
import { saveArenaId } from "../features/ReduxSlices/savearenaSlice";
import { useDispatch, useSelector } from "react-redux";
import axiosURLS from "../services/Api";
import { useNavigate } from "react-router-dom";
import Loader from "./loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function ArenaSelect() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredArenaList, setFilteredArenaList] = useState([]);
  const [filteredArenaListtwo, setFilteredArenaListtwo] = useState([]);
  const [cards, setCards] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [fromDate, setFromDate] = useState(new Date("2024-04-12"));
  const [toDate, setToDate] = useState(new Date("2024-05-12"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const maxDate = new Date(); // Current date

  const employeeID = useSelector((state: any) => state.auth.loggedInDetails);
  const cleanedEmployeeID = employeeID ? JSON.parse(employeeID) : null;

  useEffect(() => {
    if (cleanedEmployeeID) {
      setLoading(true);
      if (cleanedEmployeeID.loggedInUserType === "SUPER_ADMIN") {
        axiosURLS
          .post("/arenaDetails/all", {
            fromDate: fromDate.toISOString().split("T")[0],
            toDate: toDate.toISOString().split("T")[0],
          })
          .then((response) => {
            setFilteredArenaList(response.data);
            setFilteredArenaListtwo(response.data);
            setCards(response.data);
            setLoading(false);
          })
          .catch((error) => {
            console.log("error");
            setLoading(false);
          });
      } else {
        axiosURLS
          .get(`userProfile/${cleanedEmployeeID.userProfileId}`)
          .then((response) => {
            axiosURLS
              .post("/arenaDetails", {
                fromDate: fromDate.toISOString().split("T")[0],
                toDate: toDate.toISOString().split("T")[0],
                arenaId: response.data.orgRef.id,
              })
              .then((response) => {
                setCards([response.data]);
                setLoading(false);
              })
              .catch((error) => {
                console.log("error");
              });
          })
          .catch((error) => {
            console.log("error", error);
            setLoading(false);
          });
      }
    } else {
      setLoading(false); // Handle case where cleanedEmployeeID is null
    }
  }, [fromDate, toDate]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (value.trim() && value.length % 3 === 0) {
      // Filter the initialArenaList based on the search term
      const filteredList = filteredArenaList.filter((item: any) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setCards(filteredList);
    } else if (!value.trim()) {
      setCards(filteredArenaListtwo);
    }
  };

  const handleDivClick = (name: any) => {
    dispatch(saveArenaId(name));
    navigate("/kwanDashboardTech/" + name);
  };

  const handleFromDateChange = (date: Date) => {
    if (date > toDate) {
      setToDate(date);
    }
    setFromDate(date);
  };

  const handleToDateChange = (date: Date) => {
    if (date < fromDate) {
      setFromDate(date);
    }
    setToDate(date);
  };

  return (
    <>
      <div className="flex flex-col h-full p-8">
        <div className="flex flex-col items-center md:flex-row  mb-4 mt-15">
          <div className="flex-1 w-64 md:w-auto px-5">
            <input
              type="search"
              placeholder="Search Arena"
              className="w-full md:w-full h-11 rounded-md text-left p-2"
              value={searchTerm}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col md:flex-row items-center mb-4 md:mb-0">
            <div className="flex flex-col md:flex-row md:items-center mb-4 md:mb-0">
              <label className="mr-2 text-white">From Date:</label>
              <DatePicker
                selected={fromDate}
                onChange={handleFromDateChange}
                dateFormat="yyyy-MM-dd"
                className="mr-2 p-2 border rounded"
                maxDate={maxDate}
              />
            </div>
            <div className="flex flex-col md:flex-row md:items-center">
              <label className="mr-2 text-white">To Date:</label>
              <DatePicker
                selected={toDate}
                onChange={handleToDateChange}
                dateFormat="yyyy-MM-dd"
                className="p-2 border rounded"
                maxDate={maxDate}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="overflow-y-auto scrollbar-hide mb-16 mt-2 md:ml-3">
            <div className="flex flex-wrap">
              {cards.map((item: any, index: any) => (
                <div
                  key={index}
                  className={`w-full sm:w-1/2 md:w-1/3 lg:w-[33%] xl:w-1/3 p-4 cursor-pointer ${
                    item.pinned ? "order-first" : ""
                  }`}
                >
                  <div className="bg-blue-100 p-4 rounded-lg shadow-md hover:bg-blue-200 ">
                    <h6
                      className="text-center font-bold text-lg mb-2 pb-2 border-b border-blue-500 cursor-pointer"
                      // onClick={() => handlePinClick(item)}
                    >
                      {item.name}
                      {item.pinned ? (
                        <span className="ml-2 text-green-500">📌</span>
                      ) : (
                        <span className="ml-2 text-gray-500"></span>
                      )}
                    </h6>
                    <div
                      className="flex justify-between gap-2 p-2"
                      onClick={() => handleDivClick(item.name)}
                    >
                      <div className="flex flex-col items-center">
                        <span className="text-sm text-gray-500 text-center">
                          Occupancy
                        </span>
                        <span className="font-bold text-xl text-center">
                          {item.totalOccupancyInHours} hrs
                        </span>
                      </div>
                      <div className="flex flex-col items-center">
                        <span className="text-sm text-gray-500 text-center">
                          Revenue F&B
                        </span>
                        <span className="font-bold text-xl text-center">
                          ₹ {item.totalRevenue.bookingRevenue}
                        </span>
                      </div>
                      <div className="flex flex-col items-center">
                        <span className="text-sm text-gray-500 text-center">
                          Booking Revenue
                        </span>
                        <span className="font-bold text-xl text-center">
                          ₹ {item.totalRevenue.fnbRevenue}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
