import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import LogoutIcon from "@mui/icons-material/Logout";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useDispatch } from "react-redux";
import { logout } from "../features/ReduxSlices/authSlice";
import StadiumIcon from "@mui/icons-material/Stadium";

import { NavLink as Link, useNavigate } from "react-router-dom";
import AppRouting from "../routes/AppRouting";

import "../App.css";

const drawerWidth = 230;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  mt: "90px",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout(null));
    navigate("/kwanDashboardTech/login");
  };

  const handleArenaSelect = () => {
    // localStorage.removeItem("arena");
    navigate("/kwanDashboardTech/");
  };

  // window.localStorage.removeItem("arena");

  return (
    <div className="flex ">
      <AppBar
        position="fixed"
        open={open}
        className="bg-white p-0 pl-2 pb-2 border border-pink-400 border-b-4 shadow-xl drop-shadow-[-1px 3px 9px rgba(128, 0, 0, 1)]"
      >
        <Toolbar className="flex justify-between m-1">
          <IconButton
            className="font-bold text-xl"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 7,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/promptopiadem.appspot.com/o/six-20-logo.png?alt=media&token=08a4d2f3-a944-4da5-bb16-a660a8d6b0b1"
              width="100px"
              alt="logoImage"
            />
          </Typography>
          {/* <Typography
            component="span"
            className=" text_heading  decoration-black hidden sm:block sm:text-[35px]"
          >
            Arena Dashboard
          </Typography> */}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon className="font-bold" />
            ) : (
              <ChevronLeftIcon className="font-bold" />
            )}
          </IconButton>
        </DrawerHeader>
        <List>
          <ListItem disablePadding>
            <Link
              to="/kwanDashboardTech/"
              onClick={handleArenaSelect}
              className={({ isActive }) =>
                isActive
                  ? " active_common   active"
                  : "active_common   list-gm "
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 1.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    // justifyContent: "center",
                    color: "white",
                  }}
                >
                  <StadiumIcon />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Arena Info
                </ListItemText>
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: "flex" }}>
            <Link
              to="/kwanDashboardTech/RegisterArena"
              onClick={handleArenaSelect}
              className={({ isActive }) =>
                isActive
                  ? " active_common   active"
                  : "active_common   list-gm "
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 1.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Register New Arena
                </ListItemText>
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: "flex" }}>
            <Link
              to="/kwanDashboardTech/login"
              onClick={handleLogout}
              className={({ isActive }) =>
                isActive
                  ? " active_common   active"
                  : "active_common   list-gm "
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 1.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  LogOut
                </ListItemText>
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
      </Drawer>
      <Box className="bg-[url('../src/assets/background-image.png')] bg-no-repeat bg-cover bg-center w-screen h-screen fixed top-[4.5em] left-5">
        <AppRouting />
      </Box>
    </div>
  );
}
