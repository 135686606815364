import React, { useState } from "react";
import axiosURLS from "../services/Api";
import RegisterValue from "../Interfaces/Interfaces";
import { Formik, Form } from "formik";
import Input from "./InputCustom";
import { generatePassword } from "../utils/randomPassword";

import "../App";

export const RegisterArena: React.FC<{}> = () => {
  const [fileToUpload, setFileToUpload] = useState<any>("");
  const [employeeProfile, setEmployeeProfile] = useState<any>("");
  const [isLoading, setsIsLoading] = useState(false);
  const [isSameAddress, setIsSameAddress] = useState(false);

  const initialValues: RegisterValue = {
    arenaImageId: " ",
    name: " ",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    landmark: "",
    mobileNumber: "",
    customerLine1: "",
    customerLine2: "",
    customerCity: "",
    customerState: "",
    customerCountry: "",
    customerZipcode: "",
    customerLandmark: "",
    email: "",
    firstName: "",
    lastName: "",
    facebookUserName: "",
    InstaUserName: "",
    tikTokUserName: "",
    DateOfJoining: "",
    documentName: "",
    documentId: "",
    documentType: "",
    pictureId: "",
  };

  return (
    <div className="sm:mt-8 mb-8 mx-12 p-3 pb-[120px] sm:w-[95%] scrollbar-hide overflow-y-scroll h-screen">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          if (!fileToUpload) {
            return;
          }

          // Create a new FormData object
          const formData = new FormData();
          const employeeProfileData = new FormData();

          // Append the file to the FormData object
          formData.append("file", fileToUpload);
          formData.append(
            "payload",
            `{"bucketName": "VIRTUE_USER_UPLOADS","dirPrefix": "GENERAL_DOCS","purpose": "file upload"}`
          );
          employeeProfileData.append("file", employeeProfile);
          employeeProfileData.append(
            "payload",
            `{"bucketName": "VIRTUE_USER_UPLOADS","dirPrefix": "GENERAL_DOCS","purpose": "file upload"}`
          );

          await axiosURLS
            .post(`/fileStorage/upload/s3`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(({ data }) => {
              setsIsLoading(true);
              axiosURLS
                .post(`/arena`, {
                  name: values.name,
                  address: {
                    line1: values.line1,
                    line2: values.line2,
                    city: values.city,
                    state: values.state,
                    zipcode: values.zipcode,
                    country: values.country,
                    landmark: values.landmark,
                  },
                  contactDetail: {
                    mobileNumber: values.mobileNumber,
                    email: values.email,
                  },
                  arenaImageId: data.id,
                })
                .then(() => {
                  axiosURLS
                    .post(`/fileStorage/upload/s3`, employeeProfileData, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    })
                    .then(({ data }) => {
                      axiosURLS.post(`/employeeProfile`, {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        address: {
                          line1: isSameAddress
                            ? values.line1
                            : values.customerLine1,
                          line2: isSameAddress
                            ? values.line2
                            : values.customerLine2,
                          city: isSameAddress
                            ? values.city
                            : values.customerCity,
                          state: isSameAddress
                            ? values.state
                            : values.customerState,
                          zipcode: isSameAddress
                            ? values.zipcode
                            : values.customerZipcode,
                          country: isSameAddress
                            ? values.country
                            : values.customerCountry,
                          landmark: isSameAddress
                            ? values.landmark
                            : values.customerLandmark,
                        },
                        contactDetail: {
                          mobileNumber: values.mobileNumber,
                          email: values.email,
                        },
                        socialMediaDetail: {
                          facebookUserName: null,
                          instaUserName: null,
                          tikTokUserName: null,
                        },
                        roles: ["PREMISE_ADMIN"],
                        pictureId: data.id,
                        joiningDate: null,
                        verificationDocuments: [],
                      });
                    })
                    .then(({ data }: any) => {
                      axiosURLS.post(`/employeeProfile/createCredentials`, {
                        employeeId: data.id,
                        password: generatePassword(),
                        userName: data.contactDetail.email,
                      });
                    });
                });
              setsIsLoading(false);
              actions.setSubmitting(false);

              // Reset the form after successful submission
              actions.resetForm();
            })
            .catch((err) => {
              // console.log(fileToUpload);
              setsIsLoading(false);
            });

          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          const emptyFields = (fieldArray: string[]) => {
            fieldArray.forEach((field) => {
              setFieldValue(field, values[field as keyof typeof values]);
            });
          };

          return (
            <>
              <Form className="form-layout">
                <h2 className="my-2 font-bold col-span-2 text-3xl text-purple-500">
                  Arena Details
                </h2>
                <div className="lg:col-span-2">
                  <Input
                    label="ArenaName"
                    placeholder="eg: Gurugram"
                    name="name"
                    type="text"
                    required
                  />
                </div>
                <Input
                  label="Address Line 1"
                  placeholder="address"
                  name="line1"
                  type="text"
                  required
                />
                <Input
                  label="Address Line 2"
                  placeholder="address"
                  name="line2"
                  type="text"
                  required
                />
                <Input
                  label="City"
                  placeholder="city"
                  name="city"
                  type="text"
                  required
                />
                <Input
                  label="State"
                  placeholder="state"
                  name="state"
                  type="text"
                  required
                />
                <Input
                  label="Country"
                  placeholder="country"
                  name="country"
                  type="text"
                  required
                />
                <Input
                  label="Zipcode"
                  placeholder="zipcode"
                  name="zipcode"
                  type="tel"
                  required
                />
                <Input
                  label="Landmark"
                  placeholder="landmark"
                  name="landmark"
                  type="text"
                  required
                />
                <Input
                  label="Mobile Number"
                  placeholder="mobileNumber"
                  name="mobileNumber"
                  type="tel"
                  required
                />
                <Input
                  label="Email"
                  placeholder="email"
                  name="email"
                  type="text"
                  required
                />
                <div className="flex items-center flex-wrap">
                  <label
                    htmlFor=""
                    className="text-white text-sm mb-3 min-w-[150px]"
                  >
                    Arena Image
                    <span className="text-red-500"> *</span>
                  </label>
                  <input
                    onChange={(e: any) => {
                      setFileToUpload(e.target.files[0]);
                    }}
                    name="arenaImageId"
                    type="file"
                    className="w-full text-white border-slate-300 border rounded-md p-2 outline-none shadow-sm"
                  />
                </div>
                <h2 className="my-2 font-bold col-span-2 text-3xl text-purple-500">
                  Administrator Details
                </h2>
                <Input
                  label="FirstName"
                  placeholder="firstName"
                  name="firstName"
                  type="text"
                  required
                />
                <Input
                  label="LastName"
                  placeholder="lastName"
                  name="lastName"
                  type="text"
                  required
                />
                <span className="col-span-2 flex items-center gap-4">
                  <input
                    className="scale-110"
                    type="checkbox"
                    onChange={(e) => {
                      emptyFields([
                        "customerLine1",
                        "customerLine2",
                        "customerCity",
                        "customerState",
                        "customerCountry",
                        "customerZipcode",
                        "customerLandmark",
                      ]);
                      setIsSameAddress(e.target.checked);
                    }}
                  />
                  <label>Same as arena address</label>
                </span>
                <Input
                  label="Address Line 1"
                  placeholder="address"
                  name={isSameAddress ? "line1" : "customerLine1"}
                  type="text"
                  required
                />
                <Input
                  label="Address Line 2"
                  placeholder="address"
                  name={isSameAddress ? "line2" : "customerLine2"}
                  type="text"
                  required
                />
                <Input
                  label="City"
                  placeholder="city"
                  name={isSameAddress ? "city" : "customerCity"}
                  type="text"
                  required
                />
                <Input
                  label="State"
                  placeholder="state"
                  name={isSameAddress ? "state" : "customerState"}
                  type="text"
                  required
                />
                <Input
                  label="Country"
                  placeholder="country"
                  name={isSameAddress ? "country" : "customerCountry"}
                  type="text"
                  required
                />
                <Input
                  label="Zipcode"
                  placeholder="zipcode"
                  name={isSameAddress ? "zipcode" : "customerZipcode"}
                  type="tel"
                  required
                />
                <Input
                  label="Landmark"
                  placeholder="landmark"
                  name={isSameAddress ? "landmark" : "customerLandmark"}
                  type="text"
                  required
                />
                <Input
                  label="FacebookUsername"
                  placeholder="facebookUserName"
                  name="facebookUserName"
                  type="text"
                  required
                />
                <Input
                  label="InstaUsername"
                  placeholder="InstaUserName"
                  name="InstaUserName"
                  type="text"
                  required
                />
                <Input
                  label="TikTokUsername"
                  placeholder="tikTokUserName"
                  name="tikTokUserName"
                  type="text"
                  required
                />
                <Input
                  label="DateOfJoining"
                  placeholder="DateOfJoining"
                  name="DateOfJoining"
                  type="date"
                  required
                />
                <Input
                  label="DocumentName"
                  placeholder="documentName"
                  name="documentName"
                  type="text"
                  required
                />
                <Input
                  label="DocumentId"
                  placeholder="documentId"
                  name="documentId"
                  type="text"
                  required
                />
                <Input
                  label="DocumentType"
                  placeholder="documentType"
                  name="documentType"
                  type="text"
                  required
                />
                <div className="flex items-center flex-wrap">
                  <label
                    htmlFor=""
                    className="text-white text-sm mb-3 min-w-[150px]"
                  >
                    Employee Profile
                    <span className="text-red-500"> *</span>
                  </label>
                  <input
                    onChange={(e: any) => {
                      setEmployeeProfile(e.target.files[0]);
                    }}
                    name="employeeImage"
                    type="file"
                    className="w-full text-white border-slate-300 border rounded-md p-2 outline-none shadow-sm"
                  />
                </div>
                <div className="col-span-2">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-blue-500 text-white cursor-pointer
            p-3 font-bold  text-md flex justify-center items-center mt-5 rounded-md space-x-5 m-auto mb-5 w-32 disabled:opacity-40"
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
