import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface authState {
  arenaIdUser: any;
}

const initialState: authState = {
  arenaIdUser: localStorage.getItem("arenaId"),
};

export const arenaSlice = createSlice({
  name: "arenaId",
  initialState,
  reducers: {
    saveArenaId: (state, action: PayloadAction<any>) => {
      state.arenaIdUser = action.payload;
    },
  },
});
export const arena = (state: RootState) => state.arenaId.arenaIdUser;

export const { saveArenaId } = arenaSlice.actions;
// export const authActions = authSlice.actions

export default arenaSlice.reducer;
