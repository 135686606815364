import React from "react";
import "./App.css";
import { loggedIn } from "./features/ReduxSlices/authSlice";
import Form from "./components/Form";
import { useSelector } from "react-redux";
// import Layout from "./components/Layout";
import Lay from "./layout/Lay";
import { Toaster } from "react-hot-toast";
// import { Routes, Route, BrowserRouter } from "react-router-dom";
// import NoInternetConnection from "./components/noInternetConnection";
// import Dashboard from "./components/Dashboard";
// import PageNotFound from "./components/NoMatch";
// import { AllReports } from "./components/AllReports";

function App() {
  const loggedInUser = useSelector(loggedIn);

  return (
    <>
      {loggedInUser ? (
        <Lay />
      ) : (
        <>
          <Form />
          <Toaster />
        </>
      )}
    </>
  );
}

export default App;

// function RequireAuth({ children }: any) {
//   // let location = useLocation();
//   let [isLogin, setIslogin] = React.useState(localStorage.getItem("isLogin"));
//   setIslogin(localStorage.getItem("isLogin"));
//   if (isLogin !== "true") {
//     // return <Navigate to="../login" state={{ from: location }} replace />;
//   }
//   return children;
// }

// <NoInternetConnection>
//   {loggedInUser ? (
// <BrowserRouter>
//   <Routes>
//     <Route path="/" element={<Layout />}>
//       <Route path="/arenaSelect" element={<Dashboard />} />
//       <Route path="*" element={<PageNotFound />} />
//       <Route path="/allReport" element={<AllReports />} />
//     </Route>
//   </Routes>
// ) : (
// </BrowserRouter>
// <>
//  <Form />
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Form />} />
//         </Routes>
//       </BrowserRouter>
//     </>
//   )}
// </NoInternetConnection>
