import React, { ChangeEvent, useState } from "react";
import { login, loginDetails } from "../features/ReduxSlices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import axiosURLS from "../services/Api";
import toast from "react-hot-toast";

export default function Form() {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setsIsLoading] = useState(false);
  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  const handleLogin = async () => {
    setsIsLoading(true);
    let removeToast = toast.loading("Logging in");
    await axiosURLS
      .post("/kwanAuth/login", {
        username: form.username,
        password: form.password,
      })
      .then((res) => {
        if (res.data.accessToken) {
          dispatch(login(JSON.stringify(res?.data?.accessToken)));
          dispatch(loginDetails(JSON.stringify(res?.data)));
          toast.remove(removeToast);
          navigate("/kwanDashboardTech/");
          setsIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Invalid Credentials");
        toast.remove(removeToast);
        setsIsLoading(false);
      });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <main
      style={{
        background:
          "url(https://cdn2.whatoplay.com/screenshots/98103_1634191164slide-1.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className="h-screen w-screen grid lg:grid-cols-2"
    >
      <div className="bg-[#ffffffb1] h-full w-full flex items-center justify-center">
        <div className="p-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <h2 className="text-4xl font-semibold mb-5 text-start ">
              Log-in To Your Account
            </h2>{" "}
            <input
              className="text-xl text-black w-full sm:min-w-[400px] rounded-sm mb-4 p-4 outline-none"
              name="username"
              placeholder="Username"
              onChange={handleChange}
              type="text"
            />
            <div className="flex w-full items-center pr-4 bg-white rounded-sm mb-4">
              <input
                className="text-xl text-black w-full rounded-sm p-4 outline-none"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                type={showPassword ? "text" : "password"}
              />
              <IconButton
                className="text-black"
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOff className="text-3xl text-slate-500 cursor-pointer" />
                ) : (
                  <Visibility className="text-3xl text-slate-500 cursor-pointer" />
                )}
              </IconButton>
            </div>
            <button
              title={isLoading ? "Logging" : "Log in"}
              disabled={isLoading}
              className="bg-slate-700 rounded-sm py-3 px-8 text-white"
            >
              {isLoading ? "Logging" : "Log in"}
            </button>
          </form>
        </div>
      </div>
      <div className="hidden lg:flex  justify-center items-center">
        <div className="text-white font-extrabold hover:animate-ping">
          <h2 className="text-5xl">Welcome to</h2>
          <h1 className="text-9xl">
            {" "}
            Six
            <span className="six20">2</span>O
          </h1>
        </div>
      </div>
    </main>
  );
}
